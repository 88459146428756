<template>
  <v-app>
    <div id="mainDiv">
      <v-toolbar color="orange accent-3" prominent>
        <v-toolbar-title style="padding: 20px">
          <v-btn color="white" elevation="4" fab large v-on:click="showHomePage"
            ><v-icon dark color="orange accent-3"> mdi-home </v-icon></v-btn
          >
          Первомайские деликатесы</v-toolbar-title
        >

        <v-spacer></v-spacer>
        <div class="column mb-6">
          <p id="nameLabel">{{ nameLabel }}</p>
          <p id="roleLabel">{{ roleLabel }}</p>
        </div>

        <v-btn icon @click="exitAction">
          <v-icon>mdi-export</v-icon>
        </v-btn>
      </v-toolbar>
      <v-main
        ><v-container fluid class="pa-10">
          <v-row>
            <h1 class="ml-5">Корзина</h1>
            <v-btn
              icon
              @click="showProducts"
              class="ml-5"
              color="orange accent-3"
            >
              <v-icon color="white">mdi-backburger</v-icon>
            </v-btn>
          </v-row>
          <br />
          <v-table class="elevation-1">
            <thead>
              <tr>
                <th class="text-left">Название</th>
                <th class="text-left">кл.</th>
                <th class="text-left">цена</th>
                <th class="text-left">итого</th>
                <th class="text-left">тип</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in basket" :key="i" :value="item" col>
                <td>{{ item.product.name }}</td>
                <td>{{ item.count }}</td>
                <td>{{ item.price }}</td>
                <td>{{ item.count * item.price }}</td>
                <td>
                  <v-chip color="green">
                    {{ "Покупка" }}
                  </v-chip>
                </td>
                <td>
                  <v-icon @click="deletePurchase(i)">mdi-delete</v-icon>
                </td>
              </tr>
              <tr
                v-for="(item2, j) in basketReturns"
                :key="j"
                :value="item2"
                col
              >
                <td>{{ item2.product.name }}</td>
                <td>{{ item2.count }}</td>
                <td>{{ item2.price }}</td>
                <td>{{ item2.count * item2.price }}</td>
                <td>
                  <v-chip color="red">
                    {{ "Возврат" }}
                  </v-chip>
                </td>
                <td>
                  <v-icon @click="deleteReturn(j)">mdi-delete</v-icon>
                </td>
              </tr>
            </tbody>
          </v-table>
          <br />
          <v-row>
            <v-spacer></v-spacer>
            <h3>Сумма покупок: {{ purchasePrice }} тг</h3>
            <v-spacer></v-spacer>
            <h3>Сумма возвратов: {{ returnPrice }} тг</h3>
            <v-spacer></v-spacer>
            <h3>Итого к оплате: {{ totalPrice }} тг</h3>
            <v-spacer></v-spacer>
          </v-row>
          <br />
          <hr />
          <br />
          <v-row>
            <v-spacer></v-spacer>
            <h3 class="pt-1 mr-4">Когда доставить:</h3>
            <!-- <input type="date" v-model="deliveryDate" /> -->
            <input 
              type="date" 
              v-model="deliveryDate" 
              :min="minDate" 
              :max="maxDate"
              @input="validateDate" 
              :class="{ 'disabled-weekend': isWeekend(deliveryDate) }"
            >
            <v-spacer></v-spacer>
            <v-btn
              :loading="loading"
              @click="createOrder"
              class="px-10"
              color="orange accent-3"
              prepend-icon="mdi-cart-variant"
            >
              Подтвердить заказ
            </v-btn>
            <v-spacer></v-spacer>
          </v-row>
          <div id="alertLogin">
            <v-alert v-if="errorLabel" dismissible type="error">
              Ошибка!!!
            </v-alert>
          </div>
        </v-container>
      </v-main>
    </div>
  </v-app>
</template>
    
  <script>
import colors from "vuetify/lib/util/colors";

export default {
  name: "BasketView",
  data() {
    return {
      url: "https://boszhan.kz",
      redColor: colors.red.darken1,
      greyColor: colors.grey.lighten4,
      nameLabel: "Name",
      roleLabel: "Role",
      loading: false,
      basket: [],
      basketReturns: [],
      returnPrice: 0,
      purchasePrice: 0,
      totalPrice: 0,
      deliveryDate: "",
      errorLabel: false,
      countDown: 5,
      sendedBasket: [],
      minDate: '',
      maxDate: '', 
    };
  },
  methods: {
    exitAction() {
      localStorage.isLogedIn = "false";
      this.$router.push("/auth");
    },
    showHomePage() {
      this.$router.push("/");
    },
    showProducts() {
      this.$router.push("/products");
    },
    createOrder() {
      if (this.totalPrice > 0) {
        this.loading = true;

        this.sendedBasket = [];

        for (var i = 0; i < this.basket.length; i++) {
          let tempDict = {};
          tempDict.product_id = this.basket[i]["product"]["id"];
          tempDict.count = this.basket[i]["count"];
          tempDict.type = 0;
          tempDict.name = this.basket[i]["product"]["name"];
          tempDict.price = this.basket[i]["price"];

          this.sendedBasket.push(tempDict);
        }

        for (var j = 0; j < this.basketReturns.length; j++) {
          let tempDict2 = {};
          tempDict2.product_id = this.basketReturns[j]["product"]["id"];
          tempDict2.count = this.basketReturns[j]["count"];
          tempDict2.type = 1;
          tempDict2.name = this.basketReturns[j]["product"]["name"];
          tempDict2.price = this.basketReturns[j]["price"];
          tempDict2.reason_refund_id =
            this.basketReturns[j]["reason_refund_id"];
          if (this.basketReturns[j]["comment"] != null) {
            tempDict2.comment = this.basketReturns[j]["comment"];
          } else {
            tempDict2.comment = " ";
          }

          this.sendedBasket.push(tempDict2);
        }

        let config = {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: "Bearer " + localStorage.token,
          },
        };
        const body = {
          store_id: localStorage.storeId,
          baskets: this.sendedBasket,
          mobile_id: new Date().valueOf(),
          delivery_date: this.deliveryDate,
        };
        // console.log(body);

        this.axios
          .post(this.url + "/api/salesrep/order", body, config)
          .then(() => {
            // console.log(response.data);
            localStorage.basket = "[]";
            localStorage.basketReturns = "[]";
            this.$router.push("/");
          })
          .catch(() => {
            // console.log(JSON.parse(error.response.request.response));
            this.errorLabel = true;
            this.countDown = 5;
            this.countDownTimer();
          });

        this.loading = false;
      } else {
        this.errorLabel = true;
        this.countDown = 5;
        this.countDownTimer();
      }
    },

    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.errorLabel = false;
      }
    },

    deletePurchase(index) {
      this.basket.splice(index, 1);
      localStorage.basket = JSON.stringify(this.basket);
      this.calculatePrice();
    },
    deleteReturn(index) {
      this.basketReturns.splice(index, 1);
      localStorage.basketReturns = JSON.stringify(this.basketReturns);
      this.calculatePrice();
    },
    calculatePrice() {
      try {
        // Расчет цены покупки
        this.purchasePrice = this.basket.reduce((total, item) => {
          const count = +item.count || 0;
          const price = +item.price || 0;
          return total + (count * price);
        }, 0);

        // Расчет цены возврата
        this.returnPrice = this.basketReturns.reduce((total, item) => {
          const count = +item.count || 0;
          const price = +item.price || 0;
          return total + (count * price);
        }, 0);

        // Расчет общей цены
        this.totalPrice = this.purchasePrice - this.returnPrice;

        // Округление всех цен до двух знаков после запятой
        this.purchasePrice = Number(this.purchasePrice.toFixed(2));
        this.returnPrice = Number(this.returnPrice.toFixed(2));
        this.totalPrice = Number(this.totalPrice.toFixed(2));

      } catch (error) {
        console.error("Ошибка при расчете цены:", error);
        // Установка значений по умолчанию в случае ошибки
        this.purchasePrice = 0;
        this.returnPrice = 0;
        this.totalPrice = 0;
      }
    },

    getNextDay() {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow;
    },
    
    getMaxDate() {
      const maxDate = new Date();
      maxDate.setFullYear(maxDate.getFullYear() + 1); // Устанавливаем максимальную дату через год
      return maxDate;
    },
    
    isWeekend(dateString) {
      const date = new Date(dateString);
      const day = date.getDay();
      return day === 0 || day === 6; // 0 - воскресенье, 6 - суббота
    },
    
    validateDate() {
      const selectedDate = new Date(this.deliveryDate);
      const minDate = new Date(this.minDate);
      
      if (selectedDate < minDate) {
        this.deliveryDate = this.minDate;
      } else if (this.isWeekend(this.deliveryDate)) {
        // Если выбрана суббота, переносим на понедельник
        if (selectedDate.getDay() === 6) {
          selectedDate.setDate(selectedDate.getDate() + 2);
        } 
        // Если выбрано воскресенье, переносим на понедельник
        else if (selectedDate.getDay() === 0) {
          selectedDate.setDate(selectedDate.getDate() + 1);
        }
        this.deliveryDate = selectedDate.toISOString().split('T')[0];
      }
    },
  },
  created() {
    // Обработка имени пользователя
    this.nameLabel = "Пользователь: " + (localStorage.username || "Неизвестный");

    // Обработка имени водителя
    this.roleLabel = "Водитель: " + (localStorage.driverName || "Неизвестный");

    // Обработка корзины
    try {
      if (localStorage.basket && localStorage.basket !== "undefined") {
        this.basket = JSON.parse(localStorage.basket);
      } else {
        this.basket = [];
      }
    } catch (error) {
      console.error("Ошибка при парсинге корзины:", error);
      this.basket = [];
    }

    // Обработка возвратов
    try {
      if (localStorage.basketReturns && localStorage.basketReturns !== "undefined") {
        this.basketReturns = JSON.parse(localStorage.basketReturns);
      } else {
        this.basketReturns = [];
      }
    } catch (error) {
      console.error("Ошибка при парсинге возвратов:", error);
      this.basketReturns = [];
    }

    // Вызов метода расчета цены
    try {
      this.calculatePrice();
    } catch (error) {
      console.error("Ошибка при расчете цены:", error);
    }

    // Установка даты доставки
    this.minDate = this.getNextDay().toISOString().split('T')[0];
    this.maxDate = this.getMaxDate().toISOString().split('T')[0];
    this.deliveryDate = this.minDate;
  },
  mounted() {
    if (localStorage.isLogedIn == "false") {
      this.$router.push("/auth");
    }
  },
};
</script>
    
    
<style scoped>
#mainDiv {
  font-family: "Trebuchet MS", Helvetica, Arial, sans-serif;
  background-image: url("../assets/images/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
}

#nameLabel {
  font-weight: bold;
  font-size: 18px;
  padding: 30px 40px 0px 10px;
}

#roleLabel {
  font-size: 16px;
  padding: 0px 40px 10px 10px;
}
</style>